import { Asset, AssociatedDevice, Device, FuelType, OwnershipType, Tag } from '../types';
import { AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/components/autosuggest/AutoSuggest';
import { TagManagerTag } from '@rio-cloud/rio-uikit';

export const USER_TAG_TYPE = 'user';

export interface AssetDetailsProperties {
    asset: Asset;
    currentAssetLicensePlate: string | null;
    currentAssetLicensePlateCountryCode: string | null;
    currentAssetName: string | null;
    currentAssetStatus: string | null;
    currentAssetIdentification: string | null;
    currentAssetBrand: string | null;
    currentMasterdataAdr: string | null | undefined;
    currentMasterdataAirFreightSuitability: string | null | undefined;
    currentMasterdataComment: string | null | undefined;
    currentMasterdataCustomsBond: string | null | undefined;
    currentMasterdataCostCenter: string | null | undefined;
    currentMasterdataFuelType: string | null | undefined;
    currentMasterdataLeasingRate: number | null | undefined;
    currentMasterdataNumberOfAxes: number | null | undefined;
    currentMasterdataOwnershipEndDate: string | undefined;
    currentMasterdataOwnershipStartDate: string | undefined;
    currentMasterdataOwnershipTermInMonths: number | null | undefined;
    currentMasterdataOwnershipType: string | null | undefined;
    currentMasterdataReference: string | null | undefined;
    currentMasterdataRegisteredFirstAt: string | undefined;
    currentMasterdataTires: string | null | undefined;
    currentMasterdataOwnershipTermInMonthsChangeJustTriggered: boolean;
    currentMasterdataOwnershipEndDateChangeJustTriggered: boolean;
    currentMasterdataEngineType: string | null | undefined;
    currentMasterdataVehicleModel: string | null | undefined;
    identificationHasError: boolean;
    locale: string;
    onAssetIdentificationChange: (identification: string | null) => void;
    onAssetBrandChange: (brand: string | null) => void;
    onAssetLicensePlateChange: (licensePlate: string | null) => void;
    onAssetLicensePlateCountryCodeChange: (licensePlateCountryCode: string | null) => void;
    onAssetNameChange: (assetName: string) => void;
    onAssetStatusChange: (arg: boolean) => void;
    onMasterdataAdr: (adr: string | null | undefined) => void;
    onMasterdataAirFreightSuitability: (airFreightSuitability: string | null | undefined) => void;
    onMasterdataComment: (comment: string | null | undefined) => void;
    onMasterdataCustomsBond: (customsBond: string | null | undefined) => void;
    onMasterdataCostCenter: (costCenter: string | null | undefined) => void;
    onMasterdataFuelType: (fuelType: FuelType | null | undefined) => void;
    onMasterdataLeasingRate: (leasingRate: number | null | undefined) => void;
    onMasterdataNumberOfAxes: (numberOfAxes: number | null | undefined) => void;
    onMasterdataOwnershipType: (ownership: OwnershipType | null | undefined) => void;
    onMasterdataOwnershipEndDate: (ownershipEndDate: string | undefined) => void;
    onMasterdataOwnershipStartDate: (ownershipStartDate: string | undefined) => void;
    onMasterdataReference: (reference: string | null | undefined) => void;
    onMasterdataRegisteredFirstAt: (registeredFirstAt: string | undefined) => void;
    onMasterdataOwnershipTermInMonths: (termInMonths: number | null | undefined) => void;
    onMasterdataTires: (tires: string | null | undefined) => void;
    setIdentificationHasError: (hasError: boolean) => void;
    onMasterdataEngineType: (engineType: string | null | undefined) => void;
    onMasterdataVehicleModel: (vehicleModel: string | null | undefined) => void;
    associatedDevices: { [key: string]: AssociatedDevice[] };
}

export enum DeviceType {
    ASSET = 'asset',
    CARGOBULL = 'cargobull',
    DAKO = 'dako',
    FLEETBOARD = 'fleetboard',
    FLEET_TECH = 'fleet-tech',
    IDEM_TELEMATICS = 'idem-telematics',
    KRONE = 'krone',
    MAN_WEBFLEET = 'man-webfleet',
    NONE = 'none',
    OCU3 = 'ocu3',
    POCKET_DRIVER_APP = 'pocket-driver-app',
    RFMS = 'rfms',
    SCANIA_TELEMATICS = 'scania-telematics',
    SCHMITZ_CARGOBULL = 'schmitz-cargobull',
    TBM3 = 'tbm3',
    TIMOCOM = 'timocom',
    TOUR_EXECUTION_USER_DEVICE = 'tour-execution-user-device',
    TRAFFILOG = 'traffilog',
    TRANSICS = 'transics',
    TRUCK_LOGIN_DEVICE = 'truck-login-device',
    VCM = 'vcm',
    VOLVO_TELEMATICS = 'volvo-telematics',
    WABCO_TELEMATICS = 'wabco-telematics',
    WEBFLEET = 'webfleet',
    WEBFLEET_RETROFIT = 'webfleet-retrofit',
    CM4 = 'cm4',
    KOEGEL_TELEMATICS = 'koegel-telematics',
    SCHWARZMUELLER_TELEMATICS = 'schwarzmueller-telematics',
    RENAULT = 'renault',
    CO3 = 'co3',
}

export interface AssetAssociationWithDevice {
    id: string;
    asset_id: string;
    device_id: string;
    _embedded: {
        device: Device;
    };
}

export interface AssetAssociation {
    id: string;
    asset_id: string;
    device_id: string;
}

export interface SidebarFooterProperties {
    saveButtonDisabled: boolean;
    modalDeleteButtonDisabled: boolean;
    loadingOnSave: boolean;
    displayDeleteDialog: boolean;
    deleteAsset: () => Promise<void>;
    openDeleteDialog: () => void;
    closeDeleteDialog: () => void;
    deletionErrorMessageCode: string | null;
    updatedAsset: Asset;
    submitButtonClick: () => void;
    toggleUnsavedAssetChanges: (unsavedChanges: boolean) => void;
}

export enum SelectedTagStatus {
    'created' = 'created',
    'added' = 'added',
    'removed' = 'removed',
    'unchanged' = 'unchanged',
}

export interface SelectedTag extends Tag {
    status: SelectedTagStatus;
}

export interface CountryCode extends AutoSuggestSuggestion {
    code: string | null;
}

export interface TagManagerTagProp extends TagManagerTag {}
