export enum IncomingMessageType {
    EASY_ONBOARDING_BACK_STATE = 'EVENT_EASY_ONBOARDING_BACK',
}

export interface MessageEvent {
    data?: {
        type?: string;
    };
}
export type EasyOnboardingBackEvent = MessageEvent & {
    data?: {
        payload?: {
            url?: string;
        };
    };
};
