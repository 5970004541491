import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetsState } from './types';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import {
    Asset,
    AssetStatus,
    AssociatedDevice,
    IdentificationType,
    Masterdata,
    SortDirection,
} from '../../components/assets/types';
import { AssetsListDetailTab } from '../../containers/assets/details/types';
import { AssetAssociationWithDevice, SelectedTag } from '../../components/assets/details/types';
import omit from 'lodash/omit';
import { RootState } from '../../../../configuration/setup/store';
import { getAssetAssociatedDeviceMap } from './assetsSliceUtils';

export const initialState: AssetsState = {
    assets: [],
    fetching: false,
    tableViewType: TableViewToggles.VIEW_TYPE_TABLE,
    selectedAsset: null,
    selectedAssociatedDevices: [],
    selectedDevicesFetching: false,
    associatedDevices: {},
    assetDeleting: false,
    displayDeleteDialog: false,
    deletionSuccessful: false,
    deletionErrorMessageCode: null,
    assetUpdating: false,
    assetUpdateSuccessful: false,
    assetUpdateFailed: false,
    assetUpdateErrorCode: null,
    searchString: '',
    filters: {
        showOnlyAssetsWithoutDataSource: false,
        showActiveAssets: true,
        showArchivedAssets: true,
    },
    showFilterOptionsDialog: false,
    sortBy: null,
    sortDir: SortDirection.ASCENDING,
    tags: {
        updating: false,
        removals: {
            lastSuccess: null,
            lastFailure: null,
        },
        additions: {
            lastSuccess: null,
            lastFailure: null,
        },
    },
    easyOnboardingDialog: { show: false, selectedAssetId: undefined },
    isAssetsReloadImminent: false,
    currentAssetDetailsTab: AssetsListDetailTab.ASSET_DETAILS,
};

const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        // new - did not have explicitly defined actions before
        assetsStartLoading: (state) => {
            state.fetching = true;
            state.assets = [];
        },
        assetsLoaded: (state, action: PayloadAction<Asset[]>) => {
            state.fetching = false;
            state.assets = action.payload;
        },
        assetsLoadedFailed: (state) => {
            state.fetching = false;
            state.assets = [];
        },
        assetUpdatingStarted: (state) => {
            state.assetUpdating = true;
        },
        assetUpdatingSuccessful: (state) => {
            state.assetUpdateSuccessful = true;
            state.assetUpdateFailed = false;
        },
        assetUpdatingFailed: (
            state,
            action: PayloadAction<{
                errorCode: string | null;
            }>
        ) => {
            state.assetUpdateSuccessful = false;
            state.assetUpdateFailed = true;
            state.assetUpdateErrorCode = action.payload.errorCode;
        },
        assetUpdatingFinished: (state) => {
            state.assetUpdating = false;
        },
        selectedAssetUpdate: (
            state,
            action: PayloadAction<{
                assetId: string;
                name: string;
                license_plate: string | null;
                license_plate_country_code: string | null;
                status: AssetStatus;
                brand: string | null;
                identification_type: string | null;
                identification: string | null;
            }>
        ) => {
            state.assets = state.assets.map((stateAsset) => {
                if (stateAsset.id === action.payload.assetId) {
                    return {
                        ...stateAsset,
                        name: action.payload.name,
                        license_plate: action.payload.license_plate,
                        license_plate_country_code: action.payload.license_plate_country_code,
                        status: action.payload.status,
                        brand: action.payload.brand,
                        identification_type:
                            action.payload.identification_type === 'vin' ? IdentificationType.vin : null,
                        identification: action.payload.identification,
                    };
                }

                return stateAsset;
            });
        },
        assetDeletionStarted: (state) => {
            state.assetDeleting = true;
        },
        assetDeletionSuccessful: (
            state,
            action: PayloadAction<{
                assetId: string;
            }>
        ) => {
            state.deletionSuccessful = true;
            state.displayDeleteDialog = false;
            state.deletionErrorMessageCode = null;
            state.assets = state.assets.filter((stateAsset) => stateAsset.id !== action.payload.assetId);
        },
        assetDeletionFailed: (state) => {
            state.deletionErrorMessageCode = 'DEFAULT';
        },
        assetDeletionFinished: (state) => {
            state.assetDeleting = false;
        },

        associationsStartLoading: (state) => {
            // TODO this did not exist in old reducer
            // state.isAssetsReloadImminent = true;
        },
        associationsLoaded: (state, action: PayloadAction<AssetAssociationWithDevice[]>) => {
            state.associatedDevices = { ...getAssetAssociatedDeviceMap(action.payload) };
            state.isAssetsReloadImminent = false;
        },
        associationsLoadedFailed: (state) => {
            state.isAssetsReloadImminent = false;
        },
        assetsSelectedAssociatedDevicesChanged: (
            state,
            action: PayloadAction<{
                selectedAssociatedDevices: AssociatedDevice[];
            }>
        ) => {
            state.selectedDevicesFetching = false;
            state.selectedAssociatedDevices = action.payload.selectedAssociatedDevices;
        },
        assetsStartLoadingSelectedDevices: (state) => {
            state.selectedDevicesFetching = true;
            state.selectedAssociatedDevices = [];
        },
        assetsSelectedDevicesLoaded: (
            state,
            action: PayloadAction<{
                assetId: string;
                associatedDevices: AssociatedDevice[];
            }>
        ) => {
            state.selectedDevicesFetching = false;
            state.selectedAssociatedDevices = action.payload.associatedDevices;
            state.associatedDevices[action.payload.assetId] = action.payload.associatedDevices;
        },
        assetsSelectedDevicesLoadedFailed: (state) => {
            state.selectedDevicesFetching = false;
            state.selectedAssociatedDevices = [];
        },

        // old - had explicitly defined actions before
        assetTableViewTypeChanges: (state, action: PayloadAction<TableViewTogglesViewType>) => {
            state.tableViewType = action.payload;
        },
        assetTableRowSelected: (state, action: PayloadAction<Asset | null>) => {
            const selectedAsset = action.payload;
            state.selectedAsset = selectedAsset?.id === state.selectedAsset?.id ? null : selectedAsset;
        },
        assetTableSortChanges: (
            state,
            action: PayloadAction<{
                sortBy: string;
                sortDir: SortDirection;
            }>
        ) => {
            state.sortBy = action.payload.sortBy;
            state.sortDir = action.payload.sortDir;
        },
        assetTableSearchChanged: (state, action: PayloadAction<string>) => {
            state.searchString = action.payload;
        },
        assetTableApplyFilterChanges: (
            state,
            action: PayloadAction<{
                showOnlyAssetsWithoutDataSources: boolean;
                showActiveAssets: boolean;
                showArchivedAssets: boolean;
            }>
        ) => {
            state.filters = {
                showOnlyAssetsWithoutDataSource: action.payload.showOnlyAssetsWithoutDataSources,
                showActiveAssets: action.payload.showActiveAssets,
                showArchivedAssets: action.payload.showArchivedAssets,
            };
        },
        resetAssetUpdateState: (state) => {
            state.assetUpdating = false;
            state.assetUpdateSuccessful = false;
            state.assetUpdateFailed = false;
        },
        selectedMasterdataUpdate: (
            state,
            action: PayloadAction<{
                assetId: string;
                masterdata: Masterdata;
            }>
        ) => {
            state.assets = state.assets.map((stateAsset) => {
                if (stateAsset.id === action.payload.assetId) {
                    return {
                        ...stateAsset,
                        masterdata: action.payload.masterdata,
                    };
                }
                return stateAsset;
            });
        },
        assetDeleteDialogOpen: (state) => {
            state.displayDeleteDialog = true;
        },
        assetDeleteDialogClose: (state) => {
            state.displayDeleteDialog = false;
            state.deletionErrorMessageCode = null;
        },
        resetAssetDeletionState: (state) => {
            state.assetDeleting = false;
            state.deletionSuccessful = false;
        },
        associationDeletionStarted: (state) => {},
        associationDeletionFailed: (state) => {},
        associationDeletionFinished: (state) => {},
        associationDeletionSuccessful: (
            state,
            action: PayloadAction<{
                associationId: string;
            }>
        ) => {
            state.selectedAssociatedDevices = state.selectedAssociatedDevices.filter(
                (associatedDevice) => associatedDevice.associationId !== action.payload.associationId
            );
            state.associatedDevices = Object.fromEntries(
                Object.entries(state.associatedDevices).map(([assetId, associatedDevices]) => {
                    return [
                        assetId,
                        associatedDevices.filter(
                            (associatedDevice) => associatedDevice.associationId !== action.payload.associationId
                        ),
                    ];
                })
            );
        },
        closeEasyOnboardingDialog: (state) => {
            state.easyOnboardingDialog = { show: false, selectedAssetId: undefined };
        },
        openEasyOnboardingDialog: (state, action: PayloadAction<string | undefined>) => {
            state.easyOnboardingDialog = { show: true, selectedAssetId: action.payload };
        },
        setIsAssetReloadImminent: (state, action: PayloadAction<boolean>) => {
            state.isAssetsReloadImminent = action.payload;
        },
        clearDeviceCache: (state, action: PayloadAction<string>) => {
            state.selectedAssociatedDevices =
                state.selectedAsset?.id === action.payload ? [] : state.selectedAssociatedDevices;
            state.associatedDevices = omit(state.associatedDevices, [action.payload]);
        },
        assetTagsUpdatingStarted: (state) => {
            state.tags = {
                updating: true,
                removals: {
                    lastSuccess: null,
                    lastFailure: null,
                },
                additions: {
                    lastSuccess: null,
                    lastFailure: null,
                },
            };
        },
        assetTagsUpdatingFinished: (state) => {
            state.tags = {
                updating: false,
                removals: {
                    ...state.tags.removals,
                },
                additions: {
                    ...state.tags.additions,
                },
            };
        },
        resetAssetTagsUpdatingState: (state) => {
            state.tags = {
                updating: false,
                removals: {
                    lastSuccess: null,
                    lastFailure: null,
                },
                additions: {
                    lastSuccess: null,
                    lastFailure: null,
                },
            };
        },
        assetTagRemovingSuccessful: (
            state,
            action: PayloadAction<{
                tag: SelectedTag;
                asset: Asset;
            }>
        ) => {
            state.tags.removals = {
                lastSuccess: action.payload.tag,
                lastFailure: null,
            };
            state.assets = state.assets.map((asset) => {
                if (asset.id === action.payload.asset.id) {
                    return {
                        ...asset,
                        tags: asset.tags.filter((tagId) => tagId !== action.payload.tag.id),
                    };
                }
                return asset;
            });
            state.selectedAsset = {
                ...action.payload.asset,
                tags: state.selectedAsset
                    ? state.selectedAsset.tags.filter((tag) => tag !== action.payload.tag.id)
                    : [],
            };
        },
        assetTagRemovingFailed: (
            state,
            action: PayloadAction<{
                tag: SelectedTag;
                asset: Asset;
            }>
        ) => {
            state.tags = {
                ...state.tags,
                removals: {
                    lastSuccess: null,
                    lastFailure: action.payload.tag,
                },
            };
        },
        assetTagAddingSuccessful: (
            state,
            action: PayloadAction<{
                tag: SelectedTag;
                asset: Asset;
            }>
        ) => {
            state.tags.additions = {
                lastSuccess: action.payload.tag,
                lastFailure: null,
            };
            state.assets = state.assets.map((asset) => {
                if (asset.id === action.payload.asset.id) {
                    return {
                        ...asset,
                        tags: asset.tags.includes(action.payload.tag.id)
                            ? asset.tags
                            : [...asset.tags, action.payload.tag.id],
                    };
                }
                return asset;
            });
            state.selectedAsset = {
                ...action.payload.asset,
                tags: state.selectedAsset
                    ? [...state.selectedAsset.tags, action.payload.tag.id]
                    : [action.payload.tag.id],
            };
        },
        assetTagAddingFailed: (
            state,
            action: PayloadAction<{
                tag: SelectedTag;
                asset: Asset;
            }>
        ) => {
            state.assets = state.assets.map((asset) => {
                if (asset.id === action.payload.asset.id) {
                    return {
                        ...asset,
                        tags: asset.tags.filter((tag) => tag !== action.payload.tag.id),
                    };
                }
                return asset;
            });
            state.tags.additions = {
                lastSuccess: null,
                lastFailure: action.payload.tag,
            };
        },
        tagCreationSuccessful: (state, action: PayloadAction<{ tag: SelectedTag; asset?: Asset }>) => {
            // workaround to display tag immediately after creation. ASSET_TAG_ADDING_SUCCESSFUL will be dispatched
            // later when the backend is able to add the tag to the asset
            state.assets = state.assets.map((asset) => {
                if (asset.id === action.payload.asset?.id) {
                    return {
                        ...asset,
                        tags: asset.tags.includes(action.payload.tag.id)
                            ? asset.tags
                            : [...asset.tags, action.payload.tag.id],
                    };
                }
                return asset;
            });
        },
        tagCreationFailed: (state, action: PayloadAction<{ tag: SelectedTag }>) => {
            state.tags.additions = {
                lastSuccess: null,
                lastFailure: action.payload.tag,
            };
        },
        changeAssetDetailsTab: (state, action: PayloadAction<AssetsListDetailTab>) => {
            state.currentAssetDetailsTab = action.payload;
        },
        filterOptionsDialogClose: (state) => {
            state.showFilterOptionsDialog = false;
        },
        filterOptionsDialogOpen: (state) => {
            state.showFilterOptionsDialog = true;
        },
    },
});

export const {
    assetsStartLoading,
    assetsLoaded,
    assetsLoadedFailed,
    assetTableViewTypeChanges,
    assetTableRowSelected,
    assetTableSortChanges,
    assetTableSearchChanged,
    assetTableApplyFilterChanges,
    assetsStartLoadingSelectedDevices,
    assetsSelectedDevicesLoaded,
    assetsSelectedAssociatedDevicesChanged,
    assetsSelectedDevicesLoadedFailed,
    associationsStartLoading,
    associationsLoaded,
    associationsLoadedFailed,
    assetUpdatingStarted,
    assetUpdatingSuccessful,
    assetUpdatingFailed,
    assetUpdatingFinished,
    resetAssetUpdateState,
    selectedAssetUpdate,
    selectedMasterdataUpdate,
    assetDeleteDialogOpen,
    assetDeleteDialogClose,
    assetDeletionStarted,
    assetDeletionSuccessful,
    assetDeletionFailed,
    assetDeletionFinished,
    resetAssetDeletionState,
    associationDeletionStarted,
    associationDeletionFailed,
    associationDeletionFinished,
    associationDeletionSuccessful,
    closeEasyOnboardingDialog,
    openEasyOnboardingDialog,
    setIsAssetReloadImminent,
    clearDeviceCache,
    assetTagsUpdatingStarted,
    assetTagsUpdatingFinished,
    resetAssetTagsUpdatingState,
    assetTagRemovingSuccessful,
    assetTagRemovingFailed,
    assetTagAddingSuccessful,
    assetTagAddingFailed,
    tagCreationSuccessful,
    tagCreationFailed,
    changeAssetDetailsTab,
    filterOptionsDialogClose,
    filterOptionsDialogOpen,
} = assetsSlice.actions;

export default assetsSlice.reducer;

export const getAssets = (state: RootState) => {
    return state.assets.assets;
};

export const getAssetsTableViewType = (state: RootState) => state.assets.tableViewType;

export const getSelectedAsset = (state: RootState) => state.assets.selectedAsset;

export const getAssetTableSortColumn = (state: RootState) => state.assets.sortBy;

export const getAssetTableSortDir = (state: RootState) => state.assets.sortDir;

export const getFetching = (state: RootState) => state.assets.fetching;

export const getFetchingSelectedDevice = (state: RootState) => state.assets.selectedDevicesFetching;

export const getSelectedAssociatedDevices = (state: RootState) => state.assets.selectedAssociatedDevices;

export const getAssociatedDevices = (state: RootState) => state.assets.associatedDevices;

export const getAssetUpdating = (state: RootState) => state.assets.assetUpdating;

export const getAssetDeleting = (state: RootState) => state.assets.assetDeleting;

export const getAssetUpdateSuccessful = (state: RootState) => state.assets.assetUpdateSuccessful;

export const getAssetUpdateFailed = (state: RootState) => state.assets.assetUpdateFailed;

export const getAssetUpdateErrorCode = (state: RootState) => state.assets.assetUpdateErrorCode;

export const getDisplayDeleteDialog = (state: RootState) => state.assets.displayDeleteDialog;

export const getDeletionSuccessful = (state: RootState) => state.assets.deletionSuccessful;

export const getDeletionErrorMessageCode = (state: RootState) => state.assets.deletionErrorMessageCode;

export const getAssetTagsUpdating = (state: RootState) => state.assets.tags.updating;

export const getAssetTagLastSuccessfullyRemoved = (state: RootState) => state.assets.tags.removals.lastSuccess;

export const getAssetTagsLastFailedAdded = (state: RootState) => state.assets.tags.additions.lastFailure;

export const getAssetTagsLastFailedRemoved = (state: RootState) => state.assets.tags.removals.lastFailure;

export const getAssetTagLastSuccessfullyAdded = (state: RootState) => state.assets.tags.additions.lastSuccess;

export const getAssetDetailTab = (state: RootState) => state.assets.currentAssetDetailsTab;

export const getSearchString = (state: RootState) => state.assets.searchString;

export const getShowOnlyAssetsWithoutDataSource = (state: RootState) =>
    state.assets.filters.showOnlyAssetsWithoutDataSource;

export const getShowActiveAssets = (state: RootState) => state.assets.filters.showActiveAssets;

export const getShowArchivedAssets = (state: RootState) => state.assets.filters.showArchivedAssets;

export const getShowFilterOptionsDialog = (state: RootState) => state.assets.showFilterOptionsDialog;

export const getEasyOnboardingSelectedAssetId = (state: RootState) => state.assets.easyOnboardingDialog.selectedAssetId;

export const getIsAssetsReloadImminent = (state: RootState) => state.assets.isAssetsReloadImminent;
